@import url('https://fonts.googleapis.com/css2?family=Neuton:ital,wght@0,200;0,300;0,400;0,700;1,400&display=swap');
.hero-section {
  position: relative;
  background: url('../public/img/rozstani-large13.jpeg') no-repeat center center;
  background-size: cover;
  height: 100vh; /* This will make it full viewport height */
top: 0;
margin-bottom: 33vh;
}

.text-box {
  position: absolute;
  top: 70%;
  left: 0;
  width: 100%;
  background: linear-gradient(to bottom,rgba(248,247,246,.83),rgba(248,247,246,1) );
  text-align: center;
}
.max-width{
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
body{
  font-family: 'Neuton', serif;
background-color: rgba(248,247,246,1);
}

h1{
  font-size: 4em;
  margin-bottom: 0.5em;
}
.tex-box-wrapper p {
  font-size: 1.2em;
  line-height: 2em;
}


.amenities-section .amenity {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
}
.amenities-section .amenity p {
  margin-bottom: 0;
  margin-left: 10px;
}

.amenities-section .icon {
  margin-right: 10px;
  font-size: 24px; /* Adjust icon size as needed */
}
/* FooterSection.css */
.footer-section {
  background-color: #333; /* Or any other color you prefer */
  color: #fff;
}

.footer-logo {
  width: 150px; /* Or the size you prefer */
  margin-bottom: 15px;
}

.footer-col {
  margin-bottom: 20px;
}

.footer-col h5 {
  margin-bottom: 10px;
}

.footer-col .list-group-item {
  background-color: transparent;
  border: none;
  padding-left: 0;
}

.footer-col .list-group-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .amenities-section .amenity {
    justify-content: center;
    text-align: center;
  }
  
  .amenities-section .icon {
    margin-bottom: 5px;
    margin-right: 0;
  }
  
  .amenities-section .amenity span {
    display: block;
  }
}



@media (max-width: 1200px){
  .hero-section {
    height: 75vh;
  }
  .tex-box-wrapper p {
    font-size: 1em;
    line-height: 1.2em;
  }
  h1{
    font-size: 3em;
  }
}
@media (min-width: 1280px) {

.hero-section {
  margin-bottom: 28vh;
}
}
@media (min-width: 1800px) {

  .hero-section {
    margin-bottom: 10vh;
  }
  }


@media (min-width: 1280px) and (max-height: 768px) {
  .hero-section {
    margin-bottom: 45vh;
  }
}
@media (min-width: 1280px) and (max-height: 1200) {
  .hero-section {
    margin-bottom: 30vh;
  }
}
@media (max-width: 460px){
.hero-section {
  margin-bottom: 17vh;
}
.amenities-section div {
  padding: 10px !important;
}
    h1{
    font-size: 3em;
    margin-top: 2em;

  }
}
