.transparent-navbar {
  background-color: transparent;
  transition: background-color 0.3s;
  position: fixed;
  top: 0;
  justify-content: space-between;
  z-index: 999;
  width: 100%;
  height: 95px;
}

.navbar-container {
  max-width: 1420px;
  padding-left: 30px;
  padding-right: 30px; /* Maximum width */
  margin: auto; /* Center the navbar */
  width: 100%;
}

.sticky-nav {
  background-color: #FAFAFA;
  width: 100%;
}

.sticky-nav .nav-link {
  color: #000;
}
.bg-white .nav-link {
  color: #000;
}

.nav-link {
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 30px !important;
}
.sticky-nav .nav-link.button-main{
  border: solid 1px black;
}
.bg-white .nav-link.button-main{
  border: solid 1px black;
}


.nav-link:hover {
  color: white; /* Or any color you want */
}

.nav-link.button-main {
  background-color: rgba(248,247,246,1);
  color: rgb(30, 30, 30);
  padding-right: 8px !important;
}

.nav-link:hover::after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: white; /* Or any color you want */
}

.navbar-expand-lg .navbar-collapse {
  justify-content: flex-end;
}

/* Ensure navbar links are on the right */
.navbar-expand-lg .navbar-nav {
  margin-left: auto;
}
.sticky-nav .nav-link:hover{
  color: black;
}
.sticky-nav .nav-link:hover::after{
  background-color: black;
}
.bg-white .nav-link:hover{
  color: black;
}
.bg-white .nav-link:hover::after{
  background-color: black;
}

.nav-link.button-main:hover {
  background-color: rgb(29, 29, 29);
  color: #FAFAFA;
}
.nav-link.button-main:hover::after {
  background-color: none !important;

}
.bg-white{
  background-color: #FAFAFA !important;
}
@media (max-width: 1200px){
  .navbar-container {
    background-color: #FAFAFA;
  }
}